import React from 'react'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import PersonSharedGigs from 'components/people/PersonSharedGigs'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import MembershipInvites from 'components/memberships/MembershipInvites'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setInviteUserOverlay } from 'actions/ui'

import {
  JOINABLE_TYPE
} from 'static'

class ShowPerson extends React.Component {

  renderJoinableList(items, key, new_item_url) {
    return (
      <div className='list-group list-group-striped mb-3'>
        {_.map(items, (i) => (
          <SafeLink to={i.url} key={`${key}-${i.id}`} className='list-group-item list-group-item-action clearfix'>
            <Avatar
              url={i.avatar_url}
              classes='float-start small-avatar'
              blank_icon={key}
            />
            <strong>{i.name}</strong> @{i.username}
          </SafeLink>
        ))}
        <SafeLink
          className='list-group-item list-group-item-action text-center bold'
          to={new_item_url}
        ><i className='fi-plus'></i> Create New {key}</SafeLink>
      </div>
    )
  }

  render() {
    const person = this.props.person || {}
    return (
      <>
        <div className='d-flex flex-row align-items-center mb-3 mt-2'>
          <div className='flex-grow-0'>
            <Avatar url={person.avatar_url} />
          </div>
          <div className='flex-grow-1'>
            <PageTitle
              is_editable={person.is_editable}
              edit_link={`${person.url}/edit`}
              subtitle={person.username && (
                <h5>@{person.username}</h5>
              )}
            >
              {person.name}
            </PageTitle>
          </div>
        </div>

        {!person.is_owned && !_.isNil(person.email) && (
          <a href="#" onClick={() => this.props.setInviteUserOverlay({ ...person })}>
            Invite to {person.name} join Backline.me
          </a>
        )}

        <div className='mb-3'>
          <div>
            <ShowPhone number={person.phone} show_icon />
          </div>
          <div>
            <ShowEmail email={person.email} show_icon />
          </div>
        </div>

        {/** SHUT OFF PERSON SHARED GIGS **/}
        {false && !person.is_owner && (
          <div className='mb-3'>
            <PersonSharedGigs person_id={person.id} />
          </div>
        )}

        {person.is_owner && (
          <div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <h5>Bands</h5>
                {this.renderJoinableList(person.bands, 'Band', '/bands/new')}
              </div>

              <div className='col-12 col-md-6'>
                <h5>Venues</h5>
                {this.renderJoinableList(person.venues, 'Venue', '/venues/new')}
              </div>
            </div>

            {person.pending_memberships.length > 0 &&
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <h5>Invites</h5>
                  <MembershipInvites
                    invites={person.pending_memberships}
                  />
                </div>
              </div>
            }
          </div>
        )}

      </>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  setInviteUserOverlay
}, dispatch))(ShowPerson)
